export const DelayHydration = () => import('../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const AbandonedVisit = () => import('../../components/AbandonedVisit.vue' /* webpackChunkName: "components/abandoned-visit" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BlockImageCenter = () => import('../../components/BlockImageCenter.vue' /* webpackChunkName: "components/block-image-center" */).then(c => wrapFunctional(c.default || c))
export const BlockImgsRandom = () => import('../../components/BlockImgsRandom.vue' /* webpackChunkName: "components/block-imgs-random" */).then(c => wrapFunctional(c.default || c))
export const BlockSearch = () => import('../../components/BlockSearch.vue' /* webpackChunkName: "components/block-search" */).then(c => wrapFunctional(c.default || c))
export const BlockSlider = () => import('../../components/BlockSlider.vue' /* webpackChunkName: "components/block-slider" */).then(c => wrapFunctional(c.default || c))
export const BlockSlider2 = () => import('../../components/BlockSlider2.vue' /* webpackChunkName: "components/block-slider2" */).then(c => wrapFunctional(c.default || c))
export const BlockText = () => import('../../components/BlockText.vue' /* webpackChunkName: "components/block-text" */).then(c => wrapFunctional(c.default || c))
export const BlockWave = () => import('../../components/BlockWave.vue' /* webpackChunkName: "components/block-wave" */).then(c => wrapFunctional(c.default || c))
export const BrandLogo = () => import('../../components/BrandLogo.vue' /* webpackChunkName: "components/brand-logo" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CardAddress = () => import('../../components/CardAddress.vue' /* webpackChunkName: "components/card-address" */).then(c => wrapFunctional(c.default || c))
export const CardImg = () => import('../../components/CardImg.vue' /* webpackChunkName: "components/card-img" */).then(c => wrapFunctional(c.default || c))
export const CardLoyalty = () => import('../../components/CardLoyalty.vue' /* webpackChunkName: "components/card-loyalty" */).then(c => wrapFunctional(c.default || c))
export const CardLoyaltyBox = () => import('../../components/CardLoyaltyBox.vue' /* webpackChunkName: "components/card-loyalty-box" */).then(c => wrapFunctional(c.default || c))
export const CardLoyaltyCodeBar = () => import('../../components/CardLoyaltyCodeBar.vue' /* webpackChunkName: "components/card-loyalty-code-bar" */).then(c => wrapFunctional(c.default || c))
export const CardProduct2 = () => import('../../components/CardProduct2.vue' /* webpackChunkName: "components/card-product2" */).then(c => wrapFunctional(c.default || c))
export const CardProductCarousel = () => import('../../components/CardProductCarousel.vue' /* webpackChunkName: "components/card-product-carousel" */).then(c => wrapFunctional(c.default || c))
export const CardTitleBody = () => import('../../components/CardTitleBody.vue' /* webpackChunkName: "components/card-title-body" */).then(c => wrapFunctional(c.default || c))
export const CarrierCard = () => import('../../components/CarrierCard.vue' /* webpackChunkName: "components/carrier-card" */).then(c => wrapFunctional(c.default || c))
export const CarriersMap = () => import('../../components/CarriersMap.vue' /* webpackChunkName: "components/carriers-map" */).then(c => wrapFunctional(c.default || c))
export const CatFilter = () => import('../../components/CatFilter.vue' /* webpackChunkName: "components/cat-filter" */).then(c => wrapFunctional(c.default || c))
export const ColorsProduct = () => import('../../components/ColorsProduct.vue' /* webpackChunkName: "components/colors-product" */).then(c => wrapFunctional(c.default || c))
export const ColorsProductV2 = () => import('../../components/ColorsProductV2.vue' /* webpackChunkName: "components/colors-product-v2" */).then(c => wrapFunctional(c.default || c))
export const ContinueMyShopping = () => import('../../components/ContinueMyShopping.vue' /* webpackChunkName: "components/continue-my-shopping" */).then(c => wrapFunctional(c.default || c))
export const ContinueMyShoppingLink = () => import('../../components/ContinueMyShoppingLink.vue' /* webpackChunkName: "components/continue-my-shopping-link" */).then(c => wrapFunctional(c.default || c))
export const CookiesBar = () => import('../../components/CookiesBar.vue' /* webpackChunkName: "components/cookies-bar" */).then(c => wrapFunctional(c.default || c))
export const DoubleSquareCard = () => import('../../components/DoubleSquareCard.vue' /* webpackChunkName: "components/double-square-card" */).then(c => wrapFunctional(c.default || c))
export const DropDownMenu = () => import('../../components/DropDownMenu.vue' /* webpackChunkName: "components/drop-down-menu" */).then(c => wrapFunctional(c.default || c))
export const ErrorsMsgs = () => import('../../components/ErrorsMsgs.vue' /* webpackChunkName: "components/errors-msgs" */).then(c => wrapFunctional(c.default || c))
export const FacebookButton = () => import('../../components/FacebookButton.vue' /* webpackChunkName: "components/facebook-button" */).then(c => wrapFunctional(c.default || c))
export const FilterColors = () => import('../../components/FilterColors.vue' /* webpackChunkName: "components/filter-colors" */).then(c => wrapFunctional(c.default || c))
export const FilterPrice = () => import('../../components/FilterPrice.vue' /* webpackChunkName: "components/filter-price" */).then(c => wrapFunctional(c.default || c))
export const FilterSizes = () => import('../../components/FilterSizes.vue' /* webpackChunkName: "components/filter-sizes" */).then(c => wrapFunctional(c.default || c))
export const FollowUs = () => import('../../components/FollowUs.vue' /* webpackChunkName: "components/follow-us" */).then(c => wrapFunctional(c.default || c))
export const FooterApp = () => import('../../components/FooterApp.vue' /* webpackChunkName: "components/footer-app" */).then(c => wrapFunctional(c.default || c))
export const FooterAppTop = () => import('../../components/FooterAppTop.vue' /* webpackChunkName: "components/footer-app-top" */).then(c => wrapFunctional(c.default || c))
export const FooterTunnel = () => import('../../components/FooterTunnel.vue' /* webpackChunkName: "components/footer-tunnel" */).then(c => wrapFunctional(c.default || c))
export const FotterLinks = () => import('../../components/FotterLinks.vue' /* webpackChunkName: "components/fotter-links" */).then(c => wrapFunctional(c.default || c))
export const FullImgCard = () => import('../../components/FullImgCard.vue' /* webpackChunkName: "components/full-img-card" */).then(c => wrapFunctional(c.default || c))
export const GoogleAutoComplete = () => import('../../components/GoogleAutoComplete.vue' /* webpackChunkName: "components/google-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const GoogleView = () => import('../../components/GoogleView.vue' /* webpackChunkName: "components/google-view" */).then(c => wrapFunctional(c.default || c))
export const HeaderTunnel = () => import('../../components/HeaderTunnel.vue' /* webpackChunkName: "components/header-tunnel" */).then(c => wrapFunctional(c.default || c))
export const IconCart = () => import('../../components/IconCart.vue' /* webpackChunkName: "components/icon-cart" */).then(c => wrapFunctional(c.default || c))
export const IconFavorite = () => import('../../components/IconFavorite.vue' /* webpackChunkName: "components/icon-favorite" */).then(c => wrapFunctional(c.default || c))
export const ImageProduct = () => import('../../components/ImageProduct.vue' /* webpackChunkName: "components/image-product" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../components/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LocalisationDialog = () => import('../../components/LocalisationDialog.vue' /* webpackChunkName: "components/localisation-dialog" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MenuMobile = () => import('../../components/MenuMobile.vue' /* webpackChunkName: "components/menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const MiniCart = () => import('../../components/MiniCart.vue' /* webpackChunkName: "components/mini-cart" */).then(c => wrapFunctional(c.default || c))
export const MiniCartSlider = () => import('../../components/MiniCartSlider.vue' /* webpackChunkName: "components/mini-cart-slider" */).then(c => wrapFunctional(c.default || c))
export const MyImage = () => import('../../components/MyImage.vue' /* webpackChunkName: "components/my-image" */).then(c => wrapFunctional(c.default || c))
export const NewsLetter = () => import('../../components/NewsLetter.vue' /* webpackChunkName: "components/news-letter" */).then(c => wrapFunctional(c.default || c))
export const NewsLetterModal = () => import('../../components/NewsLetterModal.vue' /* webpackChunkName: "components/news-letter-modal" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const OurSelection = () => import('../../components/OurSelection.vue' /* webpackChunkName: "components/our-selection" */).then(c => wrapFunctional(c.default || c))
export const OurShops = () => import('../../components/OurShops.vue' /* webpackChunkName: "components/our-shops" */).then(c => wrapFunctional(c.default || c))
export const PreorderSlide = () => import('../../components/PreorderSlide.vue' /* webpackChunkName: "components/preorder-slide" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const Reasurances = () => import('../../components/Reasurances.vue' /* webpackChunkName: "components/reasurances" */).then(c => wrapFunctional(c.default || c))
export const ReferForm = () => import('../../components/ReferForm.vue' /* webpackChunkName: "components/refer-form" */).then(c => wrapFunctional(c.default || c))
export const RelaiPointCard = () => import('../../components/RelaiPointCard.vue' /* webpackChunkName: "components/relai-point-card" */).then(c => wrapFunctional(c.default || c))
export const RightBarLogin = () => import('../../components/RightBarLogin.vue' /* webpackChunkName: "components/right-bar-login" */).then(c => wrapFunctional(c.default || c))
export const SizeGuide = () => import('../../components/SizeGuide.vue' /* webpackChunkName: "components/size-guide" */).then(c => wrapFunctional(c.default || c))
export const SliderBanner = () => import('../../components/SliderBanner.vue' /* webpackChunkName: "components/slider-banner" */).then(c => wrapFunctional(c.default || c))
export const SmallCardProduct = () => import('../../components/SmallCardProduct.vue' /* webpackChunkName: "components/small-card-product" */).then(c => wrapFunctional(c.default || c))
export const SmallCardProduct2 = () => import('../../components/SmallCardProduct2.vue' /* webpackChunkName: "components/small-card-product2" */).then(c => wrapFunctional(c.default || c))
export const SubCategory = () => import('../../components/SubCategory.vue' /* webpackChunkName: "components/sub-category" */).then(c => wrapFunctional(c.default || c))
export const SubCategoryLookBook = () => import('../../components/SubCategoryLookBook.vue' /* webpackChunkName: "components/sub-category-look-book" */).then(c => wrapFunctional(c.default || c))
export const SubCategoryMark = () => import('../../components/SubCategoryMark.vue' /* webpackChunkName: "components/sub-category-mark" */).then(c => wrapFunctional(c.default || c))
export const TimeLine = () => import('../../components/TimeLine.vue' /* webpackChunkName: "components/time-line" */).then(c => wrapFunctional(c.default || c))
export const TimeLineCard = () => import('../../components/TimeLineCard.vue' /* webpackChunkName: "components/time-line-card" */).then(c => wrapFunctional(c.default || c))
export const TopBanner = () => import('../../components/TopBanner.vue' /* webpackChunkName: "components/top-banner" */).then(c => wrapFunctional(c.default || c))
export const TopFilter = () => import('../../components/TopFilter.vue' /* webpackChunkName: "components/top-filter" */).then(c => wrapFunctional(c.default || c))
export const WishListBtn = () => import('../../components/WishListBtn.vue' /* webpackChunkName: "components/wish-list-btn" */).then(c => wrapFunctional(c.default || c))
export const ZoomImages = () => import('../../components/ZoomImages.vue' /* webpackChunkName: "components/zoom-images" */).then(c => wrapFunctional(c.default || c))
export const ZoomImages2 = () => import('../../components/ZoomImages2.vue' /* webpackChunkName: "components/zoom-images2" */).then(c => wrapFunctional(c.default || c))
export const ZoomImagesV2 = () => import('../../components/ZoomImagesV2.vue' /* webpackChunkName: "components/zoom-images-v2" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepProduct = () => import('../../components/BookingDialog/BookingDialogStepProduct.vue' /* webpackChunkName: "components/booking-dialog-step-product" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShop = () => import('../../components/BookingDialog/BookingDialogStepShop.vue' /* webpackChunkName: "components/booking-dialog-step-shop" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShopConfirmation = () => import('../../components/BookingDialog/BookingDialogStepShopConfirmation.vue' /* webpackChunkName: "components/booking-dialog-step-shop-confirmation" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShopContact = () => import('../../components/BookingDialog/BookingDialogStepShopContact.vue' /* webpackChunkName: "components/booking-dialog-step-shop-contact" */).then(c => wrapFunctional(c.default || c))
export const BookingDialog = () => import('../../components/BookingDialog/index.vue' /* webpackChunkName: "components/booking-dialog" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepProduct2 = () => import('../../components/BookingDialog2/BookingDialogStepProduct2.vue' /* webpackChunkName: "components/booking-dialog-step-product2" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShop2 = () => import('../../components/BookingDialog2/BookingDialogStepShop2.vue' /* webpackChunkName: "components/booking-dialog-step-shop2" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShopConfirmation2 = () => import('../../components/BookingDialog2/BookingDialogStepShopConfirmation2.vue' /* webpackChunkName: "components/booking-dialog-step-shop-confirmation2" */).then(c => wrapFunctional(c.default || c))
export const BookingDialogStepShopContact2 = () => import('../../components/BookingDialog2/BookingDialogStepShopContact2.vue' /* webpackChunkName: "components/booking-dialog-step-shop-contact2" */).then(c => wrapFunctional(c.default || c))
export const BookingDialog2 = () => import('../../components/BookingDialog2/index.vue' /* webpackChunkName: "components/booking-dialog2" */).then(c => wrapFunctional(c.default || c))
export const ProductCardDefault = () => import('../../components/ProductCard/ProductCardDefault.vue' /* webpackChunkName: "components/product-card-default" */).then(c => wrapFunctional(c.default || c))
export const ProductCardDefaultMultiple = () => import('../../components/ProductCard/ProductCardDefaultMultiple.vue' /* webpackChunkName: "components/product-card-default-multiple" */).then(c => wrapFunctional(c.default || c))
export const ProductCardRelationShip = () => import('../../components/ProductCard/ProductCardRelationShip.vue' /* webpackChunkName: "components/product-card-relation-ship" */).then(c => wrapFunctional(c.default || c))
export const ProductCardShopLook = () => import('../../components/ProductCard/ProductCardShopLook.vue' /* webpackChunkName: "components/product-card-shop-look" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSizes = () => import('../../components/ProductCard/ProductCardSizes.vue' /* webpackChunkName: "components/product-card-sizes" */).then(c => wrapFunctional(c.default || c))
export const ProductCardTop = () => import('../../components/ProductCard/ProductCardTop.vue' /* webpackChunkName: "components/product-card-top" */).then(c => wrapFunctional(c.default || c))
export const FormsButton = () => import('../../components/forms/Button.vue' /* webpackChunkName: "components/forms-button" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductNotAvailable = () => import('../../components/products/ProductNotAvailable.vue' /* webpackChunkName: "components/products-product-not-available" */).then(c => wrapFunctional(c.default || c))
export const TransitionsSlideDownUp = () => import('../../components/transitions/SlideDownUp.vue' /* webpackChunkName: "components/transitions-slide-down-up" */).then(c => wrapFunctional(c.default || c))
export const TransitionsSlideDownUp2 = () => import('../../components/transitions/SlideDownUp2.vue' /* webpackChunkName: "components/transitions-slide-down-up2" */).then(c => wrapFunctional(c.default || c))
export const PagesCategoryFilters = () => import('../../components/pages/category/CategoryFilters.vue' /* webpackChunkName: "components/pages-category-filters" */).then(c => wrapFunctional(c.default || c))
export const PagesCategoryFilterOptions = () => import('../../components/pages/category/FilterOptions.vue' /* webpackChunkName: "components/pages-category-filter-options" */).then(c => wrapFunctional(c.default || c))
export const PagesCategoryTrieOptions = () => import('../../components/pages/category/TrieOptions.vue' /* webpackChunkName: "components/pages-category-trie-options" */).then(c => wrapFunctional(c.default || c))
export const PagesProductCarouselImages = () => import('../../components/pages/product/CarouselImages.vue' /* webpackChunkName: "components/pages-product-carousel-images" */).then(c => wrapFunctional(c.default || c))
export const PagesProductInformations = () => import('../../components/pages/product/ProductInformations.vue' /* webpackChunkName: "components/pages-product-informations" */).then(c => wrapFunctional(c.default || c))
export const PagesProductLoading = () => import('../../components/pages/product/ProductLoading.vue' /* webpackChunkName: "components/pages-product-loading" */).then(c => wrapFunctional(c.default || c))
export const PagesProductRelationship = () => import('../../components/pages/product/ProductRelationship.vue' /* webpackChunkName: "components/pages-product-relationship" */).then(c => wrapFunctional(c.default || c))
export const PagesProductSizes = () => import('../../components/pages/product/ProductSizes.vue' /* webpackChunkName: "components/pages-product-sizes" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
